import React, { useState } from 'react';
import errorImage from 'assets/images/error-img.png';
import IntlMessages from 'util/IntlMessages';
import { Button, Collapse } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { useAuthuser } from '../hooks/useAuthuser';

const allowedEnvironments = [
    'localhost',
    'sandbox',
    'staging',
    'test.garment.com',
];

const ErrorBoundaryFallBack = ({ error, errorInfo }) => {
    const [expanded, setExpanded] = useState();
    const { scope } = useAuthuser();

    const showErrorStackTrace =
        scope?.garment ||
        allowedEnvironments.some((e) => window.location.hostname.includes(e));

    return (
        error &&
        errorInfo && (
            <div role='alert' className='row m-5 justify-content-center m-auto'>
                <div
                    className={`${showErrorStackTrace ? 'col-lg-6' : ''} text-center p-3`}
                >
                    <img
                        className='center'
                        src={errorImage}
                        alt='scan batch illustration'
                        width='75%'
                    />
                    <h1 className='font-weight-bold mt-5 text-primary-color'>
                        <IntlMessages id='shared.errorBoundary.mainMessage' />
                    </h1>
                    <h4>
                        <IntlMessages id='shared.errorBoundary.secondMessage' />
                    </h4>
                    <Button
                        className='bg-primary-color font-weight-bold my-4 px-5 py-3'
                        onClick={() => window.location.reload()}
                    >
                        <FontAwesomeIcon icon={faRedo} className='mr-2' />
                        <IntlMessages id='shared.reload' />
                    </Button>
                </div>
                {showErrorStackTrace && (
                    <div className='col-lg-6 p-3 bg-white d-flex flex-column justify-content-center'>
                        <pre
                            dir='ltr'
                            style={{
                                textAlign: 'left',
                                maxHeight: '70vh',
                            }}
                        >
                            <code>
                                <h3 className='font-weight-bold'>
                                    {error.message}
                                </h3>
                                {error.stack}
                            </code>
                        </pre>
                        <Button
                            className='bg-primary-color'
                            onClick={() => setExpanded(!expanded)}
                            aria-expanded={expanded}
                            aria-label='show more'
                        >
                            Toggle full error stack trace
                        </Button>
                        <Collapse in={expanded} timeout='auto' unmountOnExit>
                            <pre
                                dir='ltr'
                                style={{
                                    textAlign: 'left',
                                    maxHeight: '70vh',
                                }}
                            >
                                <code>{errorInfo.componentStack}</code>
                            </pre>
                        </Collapse>
                    </div>
                )}
            </div>
        )
    );
};

export default ErrorBoundaryFallBack;
