import CircularProgress from '@material-ui/core/CircularProgress';
import Header from 'app/modules/shared/components/Header/Header';
import TopNav from 'components/TopNav';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import React, { Component, Suspense } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Sidebar from '../../shared/components/SideNav/SideNav';
import { getNavigationItems } from '../utils/Routes';
import styles from './Layout.module.scss';
import { ErrorBoundary } from 'app/modules/shared/error/ErrorBoundary';
import ErrorBoundaryFallBack from 'app/modules/shared/error/ErrorBoundaryFallBack';

class Layout extends Component {
    state = {
        navigationItems: [],
    };
    async componentDidMount() {
        this._isMounted = true;

        const navigationItems = await getNavigationItems(
            this.props.authUser,
            this.props.metabaseData,
        );
        if (this._isMounted) {
            this.setState({ navigationItems });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    _isMounted = false;

    render = () => {
        const { layout, drawerType, navigationStyle, horizontalNavPosition } =
            this.props;
        const { navigationItems } = this.state;

        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? 'fixed-drawer'
            : drawerType.includes(COLLAPSED_DRAWER)
              ? 'collapsible-drawer'
              : 'mini-drawer';

        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height');
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height');
        }

        // This is needed to remove the sticky header of the app in landscape mobile view in the wipReport page.
        // If 'removeStickyHeader' is true, we apply 'nonStickyHeader' style to the container div.
        // 'removeStickyHeader' is passed to the <Header /> component, to apply the style required for the AppBar
        // so as not to be fixed as well
        // Note: 'nonStickyHeader' style here and the <Header /> component, are coupled and have to be added or removed
        // together for the Header position and overflow of the app to work properly
        const pathname = this.props.location.pathname.split('/');
        const removeStickyHeader =
            pathname[2] && pathname[2].includes('wipReport');

        return (
            <div className={`app-container ${drawerStyle}`}>
                {layout.side && <Sidebar data={navigationItems} />}
                <div
                    className={`app-main-container ${styles.container} ${
                        removeStickyHeader ? styles.nonStickyHeader : ''
                    }`}
                >
                    {layout.header && (
                        <header
                            className={`app-header ${
                                navigationStyle === HORIZONTAL_NAVIGATION
                                    ? 'app-header-horizontal'
                                    : ''
                            }`}
                        >
                            {navigationStyle === HORIZONTAL_NAVIGATION &&
                                horizontalNavPosition === ABOVE_THE_HEADER && (
                                    <TopNav styleName='app-top-header' />
                                )}
                            <Header removeStickyHeader={removeStickyHeader} />
                            {navigationStyle === HORIZONTAL_NAVIGATION &&
                                horizontalNavPosition === BELOW_THE_HEADER && (
                                    <TopNav />
                                )}
                        </header>
                    )}
                    <Suspense
                        fallback={
                            <div className='loader-view h-100'>
                                <CircularProgress />
                            </div>
                        }
                    >
                        <main className='app-main-content-wrapper'>
                            <div
                                className={`app-main-content ${styles.container}`}
                            >
                                <ErrorBoundary
                                    fallbackRenderer={({
                                        error,
                                        errorInfo,
                                    }) => (
                                        <ErrorBoundaryFallBack
                                            error={error}
                                            errorInfo={errorInfo}
                                        />
                                    )}
                                >
                                    {this.props.children}
                                    <div className={styles.grow}></div>
                                </ErrorBoundary>
                            </div>
                        </main>
                    </Suspense>
                </div>
            </div>
        );
    };
}

const mapStateToProps = ({
    settings: {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        companyIdleTime,
        factoryIdleTime,
    },
    auth: { authUser },
    routes: { data },
}) => ({
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    authUser,
    companyIdleTime,
    factoryIdleTime,
    metabaseData: data,
});

export default connect(mapStateToProps)(withRouter(Layout));
