const hideDevToolPathnames = ['printView'];

export const isDevToolVisible = (pathname) => {
    const isDevMode =
        import.meta.env.MODE === 'development' ||
        import.meta.env.VITE_SHOW_DEV_TOOL;
    const isPathHidden = hideDevToolPathnames.some((hidePath) =>
        pathname.includes(hidePath),
    );

    return !isPathHidden && isDevMode;
};
