import React from 'react';
import * as Sentry from '@sentry/react';
import { __ON_PRODUCTION__ } from 'index';

export class ErrorBoundary extends React.Component {
    static getDerivedStateFromError() {
        return { hasError: true };
    }

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        if (__ON_PRODUCTION__) {
            Sentry.captureException(error, { extra: errorInfo });
        }
        console.error('Error caught by Error Boundary:', error, errorInfo);
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        const { error, errorInfo, hasError } = this.state;
        const { fallbackRenderer, children } = this.props;

        return hasError
            ? fallbackRenderer({
                  error: error,
                  errorInfo: errorInfo,
              })
            : children;
    }
}
